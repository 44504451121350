const zhcn = {
    menu:{
        "home":"首页",
        "aboutUs":"关于我们",
        "businessSectors":"业务版块",
        "successCases":"成功案例",
        "contactUs":"联系我们",
    },
    messages: {
        "upload": "上传",
        "compamy":"多思科技（大连）",
        "corporation":"有限公司",
        "slogan":"专业 · 创新 · 责任 ·  共赢",
        "button":'了解更多',
    },
    companyOverview:{
        "title":"公司概况",
        "introduction":"多思科技是一家拥有丰富经验和领先技术的IT系统解决方案公司",
        "textOne":"自2013年成立以来，我们经历了十年的发展历程,立足于行业领域的前沿，并结合广阔的市场需求，积极地钻研、消化、吸收国内外先进的管理理念和经营模式，在软件质量审计、智慧医疗、政企数字化、工业系统数字化转型等领域有深入的技术积累。",
        "textTwo":"公司本着以技术创新为核心竞争力，致力于为客户提供最适合的IT应用解决方案和高品质的系统高可用性解决方案。确实解决客户在IT应用中出现的问题，促进客户IT应用的发展，为客户创造巨大价值。",
        "corporateVision":'企业愿景',
        "corporateVisionT":'成为受社会、客户、员工尊敬的科技服务企业。',
        "corporateMission":'企业使命',
        "corporateMissionT":'通过信息技术为客户带来利益和价值。',
        "corporateValues":'企业价值观',
        "corporateValuesT":'专业·创新',
        "corporateValuesTS":'责任·共赢',
    },
    homeBusinessSector:{
        "title":"业务版块",
        "introduction":"公司在软件质量审计，智慧医疗，政企数字化等领域有着深厚的技术积累",
        businessSectorMenuOne:{
            "title":"基于Android的软件质量审计",
            "one":"基于Android的APP平台上架认证服务",
            "two":"可穿戴设备软件系统质控",
            "three":"移动设备软件系统质控",
            "four":"物联网设备软件系统质控",
            "five":"车载系统软件质控",
            "six":"智慧家居设备软件系统质控",
        },
        businessSectorMenuTwo:{
            "title":"智慧医疗IT解决方案",
            "one":"医疗信息化大数据平台",
            "two":"门诊医生工作站",
            "three":"患者信息全景查询系统",
            "four":"医技数据整合平台",
            "five":"医疗机构IT系统国产化升级改造方案",
            "six":"",
        },
        businessSectorMenuThree:{
            "title":"政企系统数字化",
            "one":"OA系统",
            "two":"基于局域网的远程会务系统",
            "three":"智慧党建系统",
            "four":"电子档案系统",
            "five":"企业技能综合培训考试管理平台",
            "six":"任务管理系统",
        },
        businessSectorMenuFour:{
            "title":"工业系统数字化转型",
            "one":"电力设施综合巡检平台",
            "two":"露天矿产量智能统计分析平台",
            "three":"矿务车辆智能调度平台",
            "four":"矿务车辆管控预警平台",
            "five":"冶金能源EMS管理平台",
            "six":"化工工艺控制信息化系统",
        },
        businessSectorMenuFive:{
            "title":"工具与服务",
            "one":"数字仓库与大数据平台",
            "two":"数据可视化分析平台",
            "three":"数据交换共享中心",
            "four":"流媒体音视频直播服务",
            "five":"珠宝智能化鉴定平台",
            "six":"增强现实虚拟实训系统",
            "seven":"区块链平台数据中台",
        },
        businessSectorMenuSix:{
            "title":"IT系统及软件定制开发",
            "one":"应用软件",
            "two":"移动APP",
            "three":"微信小程序",
            "four":"VR、AR",
            "five":"IT信息系统国产化升级改造",
            "six":"区块链、web3.0及Dapp开发",
        },
    },
    homeImplementationProcess:{
        "title":"实施流程",
        "introduction":"严格的项目实施流程，每一环节都产出相应的成果物",
        "matterTitle":"事项",
        "matterOne":"立项",
        "matterTwo":"需求分析",
        "matterThree":"UI设计",
        "matterFour":"研发",
        "matterFive":"测试",
        "matterSix":"交付",
        "matterSeven":"客户回访",
        "matterEight":"项目总结",
        "achievementTitle":"成果物",
        achievementOne:{
            "one":"项目立项书"
        },
        achievementTwo:{
            "one":"原型",
            "two":"需求文档",
            "three":"测试用例",
        },
        achievementThree:{
            "one":"UI设计图"
        },
        achievementFour:{
            "one":"项目计划表",
            "two":"进度周报",
            "three":"接口文档",
        },
        achievementFive:{
            "one":"测试报告",
            "two":"使用说明书",
        },
        achievementSix:{
            "one":"验收确认单"
        },
        achievementSeven:{
            "one":"回访调查表"
        },
        achievementEight:{
            "one":"项目总结表"
        },
    },
    homeSuccessCases:{
        "title":"成功案例",
        "introduction":"致力于为客户提供最适合的IT应用解决方案和高品质的系统高可用性解决方案",
    },
    about:{
        "title":"关于我们",
        "introduction":"",
    },
    enterpriseSize:{
        "title":"企业规模",
        "introduction":"公司总部坐落于辽宁省大连市，并在该地区拥有完善的业务网络和技术服务体系，2020年在沈阳成立分公司以扩大我们的业务范围。",
        "dl":"大连总部",
        "dl_address":"大连市高新园区创业大厦B座1004",
        "sy":"沈阳分公司",
        "sy_address":"沈阳市浑南区国际软件园E17B座408",
        "text":"我们雄心勃勃地希望将业务拓展到人工智能、智能制造、工业互联网和WEB3等领域，为客户提供更多元化的解决方案。",
    },
    honoraryQualifications:{
        "title":"荣誉资质",
        "introduction":"公司成立至今十年来，通过对技术的持续钻研和业务上的不懈努力，先后获得国家知识产权管理体系认证、国家质量管理体系认证、国家高新技术企业等多项企业资质认证，公司产品软件著作权多达三十余项。"
    },
    corporateStructure:{
        "title":"公司架构",
        "introduction":"我们拥有一支由80余位专业技术人才组成的团队，可以快速响应客户需求，并提供全面的技术服务，协助客户应对市场竞争的挑战。我们的成功在于秉承以客户为中心、技术领先、团队协作和诚信守信的价值观。我们不断追求技术创新和提升客户价值，始终保持着高度的专业水准和敬业态度。",
        "general_office":"综合办公室",
        "treasurer":"财务主管",
        "personnel_manager":"人事主管",
        "marketing_department":"市场部",
        "sales_director":"销售总监",
        "regional_manager":"区域经理",
        "production_department":"产品部",
        "product_manager":"产品经理",
        "3d_modeler":"3D建模师",
        "ui_designer":"UI设计师",
        "development_department":"研发部",
        "architect":"架构师",
        "java_engineer":"JAVA工程师",
        "app_engineer":"APP工程师",
        "c++_engineer":"C++工程师",
        "project_manager":"项目经理",
        "front_end_engineer":"前端工程师",
        "unity_engineer":"Unity工程师",
        "test_engineer":"测试工程师",
        "implementation_department":"实施部",
        "implementation_service_engineer":"实施服务工程师",
    },
    business:{
        "title":"业务版块",
        "introduction":"",
    },
    businessOne:{
        "title":"基于Android的软件质量审计",
        one:{
            "left":"作为专业的Android软件产品质量检测服务商，我们可以对所有基于Android系统运行的软件产品进行国际认可的可用性认证检测，并为客户提供精确合理的改进意见，以帮助客户产品通过各大主流机构的权威认证，为产品推广提供最大助力，帮助客户实现产品技术价值变现。",
            "right_one_title":"兼容性测试套件 (CTS)",
            "right_one_text":"Compatibility Test Suite，也就是常说的谷歌兼容性认证。 经过认证的设备理论上可以正确运行所有基于Android开发的应用。",
            "right_two_title":"安卓企业推荐(AER)",
            "right_two_text":"Android Enterprise Recommended，它甄选出了符合 Google 严格企业要求的设备和服务提供商。只要移动设备或服务带有这个标志，您便可以在企业中放心使用。",
            "right_three_title":"ARCore OEM 设备认证",
            "right_three_text":"ARCore 是 Google 的增强现实体验构建平台。借助不同的 API，ARCore 让您的手机可以感知其环境、了解世界并与信息互动。一些 API 可用于 Android 和 iOS，以实现共享 AR 体验。",
        },
        two:{
            "right_text":"对于软件产品质量审计而言，科学的流程模型才能使审计结果更加真实、客观。同时，良好的审计流程可以大幅度提升质量审计效率，更快速、更精确的识别产品存在的缺陷和问题。针对于此，我们在品质验证业务方面着重设计并研发了W质量审计流程模型，该模型可结合产品开发流程进行同步质量控制，在开发阶段严格把控质量，从而减少产品在开发过程中产生的缺陷修复工作量，提高开发工作效率。同时我们严格按照系统需求文档和规格说明书，在需求阶段对产品进行客观公正的质量评估和风险问题预警，有助于发现隐藏在产品规划阶段中的可能影响质量的缺陷。",
        },
        three:{
            "text":"公司可承接任何基于Android系统运行的软件设备，包括移动通信设备软件、智能穿戴设备操作系统、车载智能化系统、物联网等。并根据产品的使用场景、网络运行环境及功能进行全方位、客观的功能质检，同时提供真实有效的检测报告和解决方案。同时，公司可根据产品需要进行各大安卓认证机构的检测发布，为产品提供可通过机构认证的质量检测报告，帮助客户产品顺利推广。",
        },
        four:{
            "one_title":"移动通信设备软件",
            "one_text":"智能手机操作系统、APP、平板电脑操作系统、Android系统组件等",
            "two_title":"智能穿戴设备系统",
            "two_text":"智能手表操作系统、运动手环操作系统、可穿戴设备组件等",
            "three_title":"车载智能化系统",
            "three_text":"智能车机系统、车载中控大屏操作系统等",
            "four_title":"物联网系统",
            "four_text":"智能电视操作系统、智慧家居软件系统、自助机系统、医院自助设备系统、工程控制设备软件系统等",
        },
    },
    businessTwo:{
        "title":"智慧医疗IT解决方案",
        one:{
            "left":"我们依据国家行业政策和全民健康信息化领域相关标准规范，凭借十余年的行业经验和积累，以及领先的技术实力，坚持以客户为中心的理念，聚焦客户业务发展面临的压力和挑战，融合云大物移智、信息安全、中台等前沿信息技术和架构思想，为医院提供智慧医院信息化建设一站式整体解决方案，包含客户关系管理、医院核心业务、医疗管理、学科建设、运营管理、办公自动化、医疗集团建设、互联网医院、医院信息平台、 IT运维、咨询规划等方面，赋能客户持续打造更好的患者就医体验，更加便捷的操作体验，更加先进智能的管理模式，更强大的学科建设体系，更加卓越的运营体系，更加安全可靠的信息化运行体系，助力医院实现IT战略。",
        },
        two:{
            "right_title":"医疗信息化大数据平台",
            "right_text_one":"医疗信息化大数据平台方案是",
            "right_text_two":"基于HL7等医疗行业数据技术标准规范，以自研医疗ESB数据服务总线引擎为核心，参照标准技术框架和结构将院内各个医疗信息化系统采用SOA技术进行中台化集成。",
            "right_text_three":"以实现在不改变现有信息系统业务逻辑的前提下，对所有业务进行信息化数据集成及通过平台统一分发。从而",
            "right_text_four":"使院内各个信息化应用系统间的信息互联互通。",
            "right_text_five":"除此之外，平台应符合国家卫生医疗机构制定的相关信息标准规范。可以实现以下信息系统和不同科室之间的数据联通、信息共享和业务标准化数据交互。",
        },
        three:{
            "left_title":"门诊医生工作站",
            "left_text_one":"为解决医院传统信息化建设中存在的问题和弊端，如信息展示不准、不全、历史信息不完整、开单操作繁琐、便捷性差、维护困难、业务无法闭环等痛点，同时满足日益上涨的业务需求与管理需求。重点围绕完善业务联动闭环、跨类别组套创建、电子病历模板创建等关键问题，从医院实际环境出发，对标国家级智慧医院，建设符合业务需求的《门诊医生工作站系统》。 ",
            "left_text_two":"系统以门诊医生日常工作为出发点，重点提升医生在日常工作中的工作效率；整合当前系统中外挂软件的功能，实现一站式操作；理清权限，精细化服务接口；打通院前、院中、院后的患者信息链；配置灵活开放的数据接口及核查预警，方便医务工作者快速准确的查询患者的相关信息；采用半结构化电子病历的书写方式及模板创建；整合检验申请及检查申请功能，系统应具有易维护、重服务、轻客户端等特点。",
        },
        four:{
            "left_title":"患者信息全景查询系统",
            "left_text":"全景患者查询系统会将数据中心获取的数据进行统计分析并计算后，采用精确复合搜索的方式向外提供多维度的医疗信息查询服务，包括医疗影像、患者病历、诊疗数据等数据资料查询，同时，依托医疗一体化联盟的政策导向，大数据查询系统支持云影像跨院查询、患者远程会诊、患者信息共享等功能。",
            "right_title":"医技数据整合平台",
            "right_text":"医技数据整合平台以自研WSB服务总线引擎为核心构建，i数据中台形式通过SOA微服务技术与院内其他信息化系统进行数据及业务交互对接。同时支持将获取到的数据进行清洗、同步以及归档等功能实现。",
        }
    },
    businessThree:{
        "title":"政企系统数字化",
        one:{
            "text":"在大政务领域，我们致力于打造数字技术与政务服务的深度创新融合，助力整体联动、部门协同、一网通办的“互联网+政务服务”体系服务“体系持续完善、深化，显著提升政务服务的标准化、网络化、智慧化水平。打造大政务融合平台，实现信息公开、政务内搜、多规合一等服务，有效支撑新形势下以“互联网+政务服务”、“互联网+监管服务”为核心，涉及发改、工信、人社、税务、公众等多主体的大政务服务。",
        },
        two:{
            "title":"基于局域网的远程会务系统",
            "text_one":"局域网视频会议是一种基于局域网的流媒体系统作为网络时代出现的新型会议方式。具有数据和图像传送、录制、存储、信息沟通等诸多功能的视频会议方式为日常工作带来的便利是传统会议不可比拟的。",
            "text_two":"尤其在对于会议内容有内部保密要求、以及对网络环境有特殊限制的企事业单位、学校、机关等机构的各部门对工作效率和工作质量的要求不断提高的今天，基于局域网的流媒体系统建设已成为政企机构系统现代化办公建设的重要组成部分。",
        },
        three:{
            "title":"智慧党建服务系统",
            "text":"智慧党建服务系统，主要为党建业务工作开展提供支持，具体功能包括党建工作流程组建、任务管理、指导书学习、综合查询等，对应模块内容可在管理端进行维护。为政企机关党建工作提供了信息化支持，提升日常党建工作的工作效率，减少人员成本。",
        },
        four:{
            "title":"技能综合培训考试平台",
            "text":"政企培训系统用于政企或针对政企开展的一种提高人员素质、能力、工作绩效和对组织的贡献，而实施的有计划、有系统的培训和考试任务。目标在于使得员工的知识、技能、工作方法、工作态度以及工作的价值观得到改善和提高，从而发挥出员工最大潜力，进而提高个人和组织业绩，推对组织和个人不断进步，实现组织和个人的双重发展。",
        }
    },
    businessFour:{
        "title":"工业系统数字化转型",
        one:{
            "text":"在“中国制造2025”的总体目标下，工业系统数字化转型的趋势已不可避免。我司基于国家工业智能化转型的总体方针，结合自身技术实力，通过物联网、大数据、人工智能等新技术，结合客户实际需求，通过不同的信息化系统解决方案来帮助客户实现实时管理、信息深度自感知、智慧优化自决策、精准控制自执行等业务需求，从而实现工业系统方面流程精确控制与实施管理，帮助客户解决成本和时间的同时，也逐步推动制造业逐步走向智能化时代。",
        },
        two:{
            "title":"电力设施综合巡检平台",
            "text_one":"电力设施综合巡检平台是结合了无人机、传感器等设备，结合先进的图像分析技术和大数据技术，对架空输电线路和变电站进行如是否变形、锈蚀、接触不良、漏电等安全隐患的检测，以及数据智能化分析、结构化统计和可视化展示的一体化智能平台系统。",
            "text_two":"同时平台除巡检数据外，特别设计了对巡检人员和巡检主体的管理和任务派发，打通了从巡检任务下发到数据收集统计预警的业务线，最大限度的提升了电力设施巡检的效率，减少了巡检的安全风险。",
        },
        three:{
            "title":"数字化生产运营管控系统",
            "text_one":"数字化生产运营管控系统是一套可助力生产制造行业进行数字化生产运营的一站式管控系统，系统主要解决了制造行业中：生产过程进度难以把控、管理效率不高、仓储作业容易发生失误等实际问题。",
            "text_two":"通过对生产过程、质量管控、仓储管理等核心节点的信息化实现，帮助企业提升生产管理效率，优化仓储管理流程。同时基于大数据技术，实现了产品全流程质量追溯。帮助工业制造业实现信息化转型。",
        }
    },
    businessFive:{
        "title":"工具与服务",
        one:{
            "title":"数据仓库和大数据平台",
            "text":"大数据平台可以兼容各种操作系统，不仅支持对导入数据的智能识别和数据处理，同时提供了强大的可视化大屏编辑功能，包括丰富的数据源格式、各种类型的可视化组件、灵活的项目交付方式和深度优化的软件操作体验。系统整合了各行业的数据分析和决策支持的功能需求，满足最终用户在企业级报表、数据可视化分析、自助探索分析、数据挖掘建模、AI 智能分析等大数据分析需求。",
        },
        two:{
            "title":"数据可视化分析BI平台",
            "text":"大数据平台可以兼容各种操作系统，不仅支持对导入数据的智能识别和数据处理，同时提供了强大的可视化大屏编辑功能，包括丰富的数据源格式、各种类型的可视化组件、灵活的项目交付方式和深度优化的软件操作体验。系统整合了各行业的数据分析和决策支持的功能需求，满足最终用户在企业级报表、数据可视化分析、自助探索分析、数据挖掘建模、AI 智能分析等大数据分析需求。",
        },
        three:{
            "title":"彩色宝石智能化鉴定平台",
            "text_one":"彩色宝石智能化鉴定平台是我们针对在珠宝行业中，对于彩色宝石传统的人工鉴定方式主观性强，标准不明确等缺陷专门研发的数字化彩色宝石鉴定系统。",
            "text_two":"系统可以量化彩色宝石品级数据，帮助提升彩色宝石鉴定的权威性和客观性，同时减少彩色宝石鉴定过程中的人员成本。",
        }
    },
    businessSix:{
        "title":"IT系统及软件定制开发",
        one:{
            "title":"专业服务，赋能客户业务发展",
            "text":"通过建立完善的“在岸(Onsite) +近岸(Nearshore)+离岸(Offshore)”的交付服务模式，在全国范围内向客户提供全生命周期的从设备端到服务器端的软件开发/维护服务。",
        },
        two:{
            "one_t":"应用软件",
            "two_t":"APP",
            "three_t":"微信小程序",
            "four_t":"VR、AR",
            "five_t":"信息系统国产化",
            "six_t":"区块链Dapp",
        },
    },
    successCases:{
        "title":"成功案例",
        one:{
            "title":"某部三甲医院",
            "introduction":"针对某部三甲医院实际需求，全方位协力医院信息化建设工程",
            "text_one":"医院数据中心平台",
            "text_two":"医院电子病历管理系统",
            "text_three":"医院门诊医生工作站",
            "text_four":"医院医护考核管理平台",
            "text_five":"医院视频会议系统",
        },
        two:{
            "title":"某国际宝石实验室",
            "introduction":"该宝石实验室于1980年7月在洛杉矶成立。40多年来，该宝石实验室一直致力于彩色宝石的研究、鉴定、科普和推广，在建立彩色宝石市场标准化体系方面拥有丰富的经验和巨大的成就。",
            "text_one":"彩色宝石实验室检测系统",
            "text_two":"裸石贸易系统",
        },
        three:{
            "title":"国家电网某市供电公司",
            "introduction":"某市供电公司是国家特大型供电企业，负责该市的电网规划、建设、运营和电力供应，供电区域1.26万平方公里，用电客户284万，用电人口613万。公司下设16个职能部室、13个供电分公司、14个专业工区和某市电力建设集团有限公司等多经企业。",
            "text_one":"智慧党建系统",
            "text_two":"党务建设标准化一点通项目",
        }
    },
    contact:{
        "title":"联系我们",
        "contacts":"联系人",
        "contacts_name":"杨先生",
        "telephone":"联系电话",
        "phone":"155-4251-7211",
        "email":"邮箱",
        "emails":"yangjian@duostec.com",
    }

}
export default zhcn
